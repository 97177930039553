<!-- Main Page -->
<div id="page">
    <form [formGroup]="attendanceForm">
        <input type="hidden" value="" formControlName="Latitude">
        <input type="hidden" value="" formControlName="Longitude">
        <!-- Header -->
        <div class="header header-fixed header-logo-center">
            <span (click)="back()" data-back-button class="header-icon header-icon-1 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/chevron-left.png" alt="" />
            </span>
            <h2 class="header-title textcolor">Attendance Date</h2>
            <span (click)="onclick()" data-menu="menu-sidebar-left-1" class="header-icon header-icon-4 textcolor">
                <img width="16" height="16" src="../../../assets/images/icons/align-left.png" alt="" />
            </span>
        </div>
        <!-- Header -->
        <!-- Main Content-->
        <div class="page-content detail header-clear-medium" (click)="onremoveclick()">
            <div class="content">
                <div class="inputsection">
                    <span class="input-title font-14">Date<span class="requird-field">*</span></span>
                    <div class="input-style input-required dob-input">
                        <input id="AttendanceDate" class="form-control" type="text" mwlFlatpickr
                            (change)="ChangeDate($event)" formControlName="AttendanceDate" dateFormat="m/d/Y"
                            ref="flatpickr" maxDate="today">
                    </div>
                </div>
                <div class="inputsection">
                    <button (click)="openBatchesModal()"
                        class="btn mt-2 btn-block loginheadercolor textcolor">Next</button>
                </div>
            </div>
            <!-- <img style="display: none;" width="16" height="16" src="../../../assets/images/icons/chevron-left.png"
                alt="" />
            <img style="display: none;" class="editiconbtn" alt=""
                src="../../../assets/images/KridApp-Image/editicon.png"> -->

            <!-- <mat-calendar class="mb-3 bg-white" #calendar [dateClass]="isSelected"></mat-calendar> -->
            <!-- <div class="card card-style">
                <div class="content">
                    <span class="input-title font-14">Date<span class="requird-field">*</span></span>
                    <div class="input-style input-required dob-input">
                        <input id="AttendanceDate" class="form-control" type="text" mwlFlatpickr (change)="ChangeDate($event)"
                         formControlName="AttendanceDate" dateFormat="m/d/Y" ref="flatpickr" maxDate="today">
                    </div>
                </div>
                <div (click)="openBatchesModal()" class="content relative">
                    <a data-menu="ad-timed-2">
                        Select Batches<span class="requird-field">*</span>
                        <i class="fa fa-angle-right accordion-icon"></i>
                    </a>
                    <div class="displayname-selected">{{SelectedCheckboxNameBatch}}</div>
                </div>
            </div>

            <div class="card card-style" *ngIf="HideShowSchedule">
                <div class="content relative" (click)="openScheduleModal()">
                    <a  data-menu="ad-timed-schedule">
                        Select Schedule
                        <i class="fa fa-angle-right  accordion-icon"></i>
                    </a>
                    <div class="displayname-selected">{{SelectedCheckboxNameSchedule}}</div>
                </div>
            </div>

            <div class="card card-style" *ngIf="HideShowDrill">
                <div class="content" (click)="openDrillModal()">
                    <a  data-menu="ad-timed-drill">
                        Select Drill
                        <i class="fa fa-angle-right accordion-icon"></i>
                    </a>
                </div>
            </div>

            <div class="card card-style" *ngIf="HideShowStudent">
                <div class="content relative" (click)="openStudentlistModal()">
                    <a  data-menu="ad-timed-student">
                        Select Student
                        <i class="fa fa-angle-right  accordion-icon"></i>
                    </a>
                    <div class="displayname-selected">{{SelectedStudentCount}}</div>
                </div>
            </div>

            <div class="card card-style">
                <div class="accordion" id="accordion-1">
                    <div class="mb-0">
                        <button type="button" class="btn accordion-btn" data-toggle="collapse" data-target="#Homework">
                            Homework
                            <i class="fa fa-chevron-down font-10 accordion-icon"></i>
                        </button>
                        <div id="Homework" class="collapse accordion-control" data-parent="#accordion-1">
                            <textarea class="form-control" rows="2" placeholder="Homework"
                                formControlName="homework"></textarea>
                        </div>
                    </div>
                    <div class="divider divider-margins"></div>
                    <div class="mb-0">
                        <button type="button" class="btn accordion-btn" data-toggle="collapse" data-target="#Diet">
                            Diet
                            <i class="fa fa-chevron-down font-10 accordion-icon"></i>
                        </button>
                        <div id="Diet" class="collapse accordion-control" data-parent="#accordion-1">
                            <textarea class="form-control" rows="2" placeholder="Diet"
                                formControlName="diet"></textarea>
                        </div>
                    </div>
                    <div class="divider divider-margins"></div>
                    <div class="mb-0">
                        <button type="button" class="btn accordion-btn" data-toggle="collapse" data-target="#Comments">
                            Comments
                            <i class="fa fa-chevron-down font-10 accordion-icon"></i>
                        </button>
                        <div id="Comments" class="collapse accordion-control" data-parent="#accordion-1">
                            <textarea class="form-control" rows="2" placeholder="Comments"
                                formControlName="comments"></textarea>
                        </div>
                    </div>
                </div>
            </div> -->


        </div>

        <!-- Main Content-->
        <div class="alert alert-warning alert-dismissible fade show " role="alert" *ngIf="isShow">
            <strong>Holy guacamole!</strong> You should check in on some of those fields below.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </form>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
        <p class="color-white"> Loading... </p>
    </ngx-spinner>
</div>